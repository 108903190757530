import i18next from 'i18next';

import { initReactI18next } from 'react-i18next';

const resources = {
  hr: {
    translation: {
      navigation: {
        home: 'NASLOVNICA',
        about: 'O NAMA',
        services: 'USLUGE',
        portfolio: 'VIZIJA',
        contact: 'KONTAKT'
      },

      title1: 'Donosimo život u',
      title2: 'vaš vrt',


      about: {
        title: 'Naša priča',
        subtitle: 'Svoje sam ideje prvi put primijenio vodeći tim ljudi koji je osmislio, uredio i održavao organski vrt u prekrasnom Maslina Resort-u, hotelu s pet zvjezdica, s pogledom na starogradski zaljev.',
        text1:
          ` Zovem se Mario Gracin i većinu svog dosadašnjeg života proveo sam u gradu Zagrebu, ali ljetni mjeseci uvijek su bili rezervirani za opuštanje
            na otoku Hvaru, u kući moga djeda. Godinama sam ga gledao kako vješto i predano brine o bilju, hraneći cijelu obitelj hranom koju je sam uzgojio.
            Uživajući u vremenu provedenom s djedom u polju i hrani koju bi uzgojili, rodio se moj san.<0/><0/>
            Na Sveučilištu u Zagrebu završio sam studij ekološke poljoprivrede, koja me naučila kako koristiti prirodna načela uzgoja bilja bez umjetnih gnojiva i
            kemijskih pesticida. Tokom studiranja shvatio sam genijalnost prirode koja nas okružuje i time sam se vodio u svom daljnjem radu. Priroda posjeduje
            sve što joj je potrebno da buja i raste. Kada je tako počnemo promatrati naša uloga postaje vrlo jednostavna, a temelji se na suradnji. 2020. godine
            odlučio sam trajno preseliti u Stari Grad na Hvaru. Svoje sam ideje prvi put primijenio vodeći tim ljudi koji je osmislio, uredio i održavao organski
            vrt u prekrasnom Maslina Resort-u, hotelu s pet zvjezdica, s pogledom na starogradski zaljev.`,
        text2:
          ` Nakon toga, osnovao sam SOIL,tvrtku koja stečena znanja o funkcioniranju prirodnih ekosustava primjenjuje u stvaranju novih vrtova i okućnica.
            Mi vjerujemo da je čovjek integralni dio ekosustava i da specijalnim dizajnom i upravljanjem ima mogućnosti imati pozitivan utjecaj na okoliš i klimatske promjene.
            U dizajnu se vodimo principima raznolikosti bilja i plodnosti
            tla iz kojeg biljke rastu, da bi vaši vrtovi svake godine bili bogatiji plodovima i otporniji. Naši vrtovi pomažu ukloniti ugljični dioksid iz zraka
            i vratiti ga u tlo, pozitivno utječući na klimu. Da su naši vrtovi ljudi, mogli bi reći da bi naš cilj bio jačanje prirodnog imuniteta tijela da
            tijelo kroz godine postaje ljepše i otpornije.<0/><0/>
            Pri dizajniranju i izradi novih vrtova, uvijek se vodimo potrebama naših klijenata. Izgled i funkcionalnost vrta prilagođavamo individualnim potrebama
            i željama, bilo da je primarna funkcija vrta opuštanje, zabava, kuhanje na otvorenom ili nešto drugo. Krajnji cilj svakog od naših vrtova je da stvorimo
            prostor u kojem ljudi žele provoditi svoje vrijeme, u kojem se osjećaju ugodno i opušteno te povezano s prirodom koja ih okružuje.`
      },

      services: {
        title: 'Naša ponuda',
        offer: [
          'dizajn/redizajn, izvođenje i održavanje privatnih i poslovnih vrtova, javnih zelenih površina i jestivih šuma',
          'uređenje i dizajn unutrašnjih vrtova ili vrtova na vašim terasama',
          'savjetovanje i radionice o raznim primjenama regenerativnih praksi',
          'korištenje pasivnih metoda prikupljanja kišnice',
          'projektiranje, instalacija i održavanje Rain Bird sustava za navodnjavanje'
        ]
      },

      portfolio: {
        vision: `Naša je vizija da budemo lideri u stvaranju unikatnih mediteranskih vrtova koji vas ljepotom svojih boja i mirisa pozivaju da u njima provedete
                 što više vremena. Kroz promišljeni dizajn, primjenjujući regenerativne principe želimo našim klijentima i lokalnoj zajednici pokazati koliko je
                 priroda genijalna i kako ukoliko poštujemo njezine zakonitosti bezbrižno možemo uživati u njenim ljepotama i plodovima, kako sada, tako i u budućnosti.`
      },

      contact: {
        title: 'Upoznajmo se',
        position: 'OSNIVAČ & AGRONOM',
        phone: 'Telefon'
      }
    },
  },
  en: {
    translation: {
      navigation: {
        home: 'HOME',
        about: 'ABOUT US',
        services: 'SERVICES',
        portfolio: 'VISION',
        contact: 'CONTACT'
      },

      title1: 'Bringing life to',
      title2:'your garden',

      about: {
        title: 'Our story',
        subtitle: 'I first put my philosophy to work leading the team that laid out the organic gardens for the beautiful five-star Maslina Resort, overlooking Stari Grad Bay.',
        text1:
          `My name is Mario Gracin. I grew up in the big city of Zagreb, but the Summer months of my youth always meant living in my grandfather’s
           house on the island of Hvar. For years, I watched my grandfather as he worked his plot of land with skill and devotion, feeding us all
           from what he grew. My dream was born here.<0/><0/>
           At university in Zagreb, I studied ecological agronomy, which taught me how to use scientific principles to cultivate growing things
           without fertilizers, pesticides or other artificial chemical intervention. This is where I developed and refined the vision that has
           governed everything I have done since: Nature herself provides everything she needs to stay healthy, to flourish and to inspire her
           caretakers to cooperate with her. Everything came together in 2020, when I moved permanently to the town of Stari Grad on my grandfather’s island of Hvar.
           I first put my philosophy to work leading the team that laid out the organic gardens for the beautiful five-star Maslina Resort, overlooking Stari Grad Bay.`,
        text2:
          `Shortly after, I founded SOIL, the company that embodies everything I believe about how gardens should be created and
          cared for—as a true partnership where man not only helps nature sustain herself, but helps her enhance herself over time.
          The goal of sustainability is fine as far as it goes, but it does not go far enough. At SOIL, our guiding principle is “regeneration.”
          Designed with the right mix of diverse plant life, a garden’s soil can fertilize itself, becoming healthier and more resilient
          through the years. It can remove carbon dioxide from the air and return it to the soil, making a positive impact on the climate.
          If a garden were a human being, we would be stimulating the body’s own immune system to keep it youthful, healthy and looking beautiful as it ages.<0/><0/>
          Of course, we never forget that the real beneficiaries of our work are the garden’s owners. We aim to help our clients enjoy the best of Mediterranean
          outdoor living, tailoring their gardens to their particular needs for relaxing, entertaining and eating. When all is said and done, our gardens are meant to serve them.`
      },

      services: {
        title: 'We offer',
        offer: [
          'design/redesign, implementation & maintenance of private and commercial gardens, public green spaces, orchards & food forests',
          'indoor, terrace and balcony landscaping and design',
          'consulting, workshops & education on regenerative practices',
          'rainwater harvesting methods',
          'Rain Bird irrigation systems'
        ]
      },

      portfolio: {
        vision: `Our vision is to be a leader in creating beautiful Mediterranean boutique gardens that are completely natural, resilient and invite you to spend more time outdoors while educating and inspiring
                 our clients, employees & the local community to be a conscious part of nature's brilliance.`
      },

      contact: {
        title: 'Let\'s meet',
        position: 'FOUNDER & AGRONOMIST',
        phone: 'Phone'
      }
    },
  },
};

i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
