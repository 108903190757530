import './Portfolio.css';

import { useTranslation } from 'react-i18next';

import sunImage from '../../assets/home/sun.png';


const Portfolio = () => {
  const { t } = useTranslation();

  return (
    <div className='portfolio-container d-flex align-items-center' id='vision'>
      <div className='container'>
        <div className='row'>
         <img src={sunImage} alt='Sun' className='yellow-filter'/>
          <div className='col-md-12 text-center'>
            <h4>{ t('portfolio.vision') }</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
