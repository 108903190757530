import './App.css';

import NavBar from './components/NavBar/NavBar';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Services from './pages/Services/Services';
import Portfolio from './pages/Portfolio/Portfolio';
import Contact from './pages/Contact/Contact';

import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();

  const routes = [
    {
      title: t('navigation.about'),
      path: "#about"
    },
    {
      title: t('navigation.services'),
      path: "#services"
    },
    {
      title: t('navigation.portfolio'),
      path: "#vision"
    },
    {
      title: t('navigation.contact'),
      path: "#contact"
    }
  ];

  return (
    <div>
      <NavBar routes={ routes } />

      <Home />
      <About />
      <Services />
      <Portfolio />
      <Contact />

      <div className="container-fluid text-center align-items-center justify-content-center copyright">
        Copyright © 2023 Soil.
      </div>
    </div>
  );
}

export default App;
