import './About.css';

import { useTranslation, Trans } from "react-i18next";

import beeImage from '../../assets/home/bee.png';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="about-container" id="about">
      <div className="container">
        <div className="row">
          <div className="col-md-4 first-col">
            <h1><i>{ t('about.title') }</i></h1>
            <div className='bees green-filter'>
              <img src={beeImage} alt='Bee' className='bee-img-sm'/>
              <img src={beeImage} alt='Bee' className='bee-img-md'/>
              <img src={beeImage} alt='Bee' className='bee-img-lg'/>
              <br/><br/>
              <img src={beeImage} alt='Bee' className='bee-img-sm'/>
              <img src={beeImage} alt='Bee' className='bee-img-md'/>
            </div>
            <h5 className='subtitle'>
              { t('about.subtitle') }
            </h5>
          </div>
          <div className="col-md-4">
            <p className='text-left'>
              <Trans i18nKey='about.text1' components={[<br />]}/>
            </p>
          </div>
          <div className="col-md-4">
            <p className='text-left'>
              <Trans i18nKey='about.text2' components={[<br />]}/>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;