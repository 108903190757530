import './Services.css';

import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="services-container" id="services">
      <div className="container">
        <div className="row" style={{ marginTop: '2em' }}>
          <div className="col-md-4">
            <h1><i>{ t('services.title') }</i></h1>
          </div>
          <div className="col-md-8 text-center">
            <ul>
              <li className='text-sm-begin'>
                { t('services.offer.0') }
              </li>
              <li>
                { t('services.offer.1') }
              </li>
              <li>
                { t('services.offer.2') }
              </li>
              <li>
                { t('services.offer.3') }
              </li>
              <li>
                { t('services.offer.4') }
              </li>
            </ul>
            <br/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;