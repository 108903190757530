import './Contact.css'

import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="contact-container" id="contact">
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-4 col-sm-12">
            <h1><i>{ t('contact.title') }</i></h1>
          </div>
          <div className="offset-md-1 col-md-3 col-sm-12">
            <p>
              <span className="strong"><i>Mario Gracin</i></span><br/>
              { t('contact.position') }<br/>
              <i>{ t('contact.phone') }</i><br/>
              +385955194373<br/>
              <i>Email</i><br/>
              MEETUS@SOIL.TEAM
            </p>
            <p className="mt-4">
              <a className="mx-3" href="https://instagram.com/soil.team?igshid=ZWIzMWE5ZmU3Zg=="><i className="bi bi-instagram"></i></a>
              <a className="mx-3" href="https://www.linkedin.com/company/soil.team/"><i className="bi bi-linkedin"></i></a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
