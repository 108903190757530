import './NavBar.css';

import { useState } from 'react';
import { useTranslation } from "react-i18next";

const NavBar = ({ routes }) => {
  const { t, i18n } = useTranslation();

  const [active, setActive] = useState(false);
  const switchActivity = () =>{
    if (window.scrollY >= 80){
      setActive(true);
    } else {
      setActive(false);
    }
  };
  window.addEventListener('scroll', switchActivity);

  return (
    <nav className={ "navbar navbar-light navbar-expand-lg p-1 w-100 d-flex flex-column fixed-top " + (active ? "navbar-scrolled" : "") }>
      <div className="container">
        <a className="navbar-brand d-block d-lg-none" href="/">
          <img src='logo-dark.png' height="80" alt="Logo" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse">
          <ul className="navbar-nav justify-content-between w-100">
            <li className="nav-item flex-even">
              <span className="nav-link d-inline-block" onClick={ () => { i18n.changeLanguage( 'hr' ) } }>HR</span> |
              <span className="nav-link d-inline-block" onClick={ () => { i18n.changeLanguage( 'en' ) } }>EN</span>
            </li>
            <li className="nav-item d-none d-lg-block flex-even">
              <a className="nav-link mx-1" href="/">
                <img src='logo-dark.png' height="120" alt="Logo" />
              </a>
            </li>
            <li className="nav-item justify-content-between flex-even">
              <a className="nav-link mx-1" target='_blank' rel='noreferrer' href="mailto:meetus@soil.team">meetus@soil.team</a>
              <a className="nav-link mx-1" target='_blank' rel='noreferrer' href="tel:+385955194373">+385955194373</a>
              <a className="nav-link mx-1" target='_blank' rel='noreferrer' href="https://instagram.com/soil.team?igshid=ZWIzMWE5ZmU3Zg=="><i className="bi bi-instagram"></i></a>
              <a className="nav-link mx-1" target='_blank' rel='noreferrer' href="https://www.linkedin.com/company/soil.team/"><i className="bi bi-linkedin"></i></a>
            </li>
          </ul>
        </div>
      </div>
      <div className="container-fluid navbar-dropdown">
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item flex-even d-md-none">
              <span className="nav-link d-inline-block" onClick={ () => { i18n.changeLanguage( 'hr' ) } }>HR</span> |
              <span className="nav-link d-inline-block" onClick={ () => { i18n.changeLanguage( 'en' ) } }>EN</span>
            </li>

            <li className="nav-item">
              <a className="nav-link mx-lg-4" aria-current="page" href="/">{ t('navigation.home') }</a>
            </li>
            {
              routes.map((item, index) => {
                return (
                  <li className="nav-item" key={index}>
                    <a className="nav-link mx-lg-4" href={ item.path }>{ item.title }</a>
                  </li>
                );
              })
            }
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
