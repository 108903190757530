import './Home.css'

import { useTranslation } from 'react-i18next';

import lemonImage from '../../assets/home/lemon.png';


const Home = () => {
  const { t } = useTranslation();

  return (
    <div className='home-container container-fluid'>
      <div className='row-first d-flex justify-content-sm-center justify-content-md-end col-12'>
          <img src={lemonImage} alt='Lemon' className=' lemon-img yellow-filter'/>
          <img src={lemonImage} alt='Lemon' className='lemon-img yellow-filter'/>
      </div>
      <div className='row-second d-flex justify-content-sm-center justify-content-md-end'>
        <img src={lemonImage} alt='Lemon' className='lemon-img yellow-filter'/>
        <img src={lemonImage} alt='Lemon' className='lemon-img yellow-filter'/>
      </div>
      <div className='d-flex align-items-center justify-content-sm-center justify-content-md-left'>
        <h1>{ t('title1') }</h1>
        <h1>{ t('title2') }</h1>
      </div>
    </div>
  );
};

export default Home;